import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appApiResources } from '../../configuration/app.constants';
import { notification } from 'src/app/services/notification/notification';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: any;
    data2: any;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('sjriUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }



    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(appApiResources.userLogin, { email, password })
            .pipe(map(data => {
                this.user = data.data;

              //  if (this.user.role == 'FIELDWORKER' || this.user.role == 'PUBLIC') {
               //     notification("error", "This role have no access");
              //      return;
              //  }

                // login successful if there's a jwt token in the response
                // if (this.user && this.user.accessToken && this.user.role == 'user') {
                if (this.user && this.user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('sjriUser', JSON.stringify(this.user));
                    this.currentUserSubject.next(this.user);
                }
                return this.user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('sjriUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
        window.location.href = '/';
    }

}



