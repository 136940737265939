import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,) {

  }

  public hide: Boolean = false
  public path: any
  ngOnInit(): void {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
        this.refresh(event.urlAfterRedirects);
      }
    });

  }


  refresh(url) {

    //  this.router.events.pipe(
    //    filter(event => event instanceof NavigationEnd)
    //  ).subscribe((event: any) => {
    //  switch (event.url) {
    switch (url) {
      case "/user-management":
        this.path = "User Management"
        break;
      case "/":
        this.path = "Project & Field Workers"
        break;
      case "/ingredients":
        this.path = "Raw Ingredients"
        break;
      case "/recipe-master":
        this.path = "Food Master"
        break;
      case "/project-listing":
        this.path = "Project Listing & Management"
        break;
      case "/responded-data":
        this.path = "Respondent Data"
        break;
      case "/field-worker":
        this.path = "Project & Field Workers"
        break;

    }
    //  });
    const currentUser = JSON.parse(localStorage.getItem('sjriUser'));
    if (currentUser) {
      this.hide = true
    } else {
      this.hide = false;
    }
    


  }

}
