<nav id="sidebar" *ngIf="hide">
  <div class="sidebar-header   text-center"> <!--class lgo-->
    <img src="assets/img/icons/SJRI.png" class="logo" alt="" />
  </div>

  <ul class="list-unstyled components mt-3">

    <!--<li routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
      <img src="assets/img/icons/noun_Worker_1974872.svg" alt="" />
      <a [routerLink]="['/']"  >Dashboard</a>
    </li>-->

    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="userType.role != 'PUBLIC'">
      <img src="assets/img/icons/man.svg" alt="" />
      <a [routerLink]="['/user-management']">User Management</a>
    </li>

    <li routerLinkActive="active" *ngIf="userType.role == 'ADMIN' || userType.role == 'RESEARCHER'   || userType.role == 'RESEARCHER_L2' " >  <!--*ngIf="userType.role == 'ADMIN'"-->
      <img src="assets/img/icons/nutrition.svg" alt="" />
      <a [routerLink]="['/ingredients']">Raw Ingredients</a>
    </li>

    <li routerLinkActive="active" *ngIf="userType.role == 'ADMIN'  || userType.role == 'RESEARCHER'   || userType.role == 'RESEARCHER_L2'" > <!--*ngIf="userType.role == 'ADMIN'"-->
      <img src="assets/img/icons/recipe.svg" alt="" />
      <a [routerLink]="['/recipe-master']">Recipes</a>
    </li>

    <li routerLinkActive="active" *ngIf="userType.role != 'PUBLIC'">
      <img src="assets/img/icons/presentation.svg" class="custom-top" alt="" />
      <a [routerLink]="['/project-listing']">Project Listing & Management</a>
    </li>

    <li routerLinkActive="active">
      <img src="assets/img/icons/marketing.svg" alt="" />
      <a [routerLink]="['/responded-data']">Respondent Data</a>
    </li>
    
    <li routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }" >
      <img src="assets/img/icons/noun_Worker_1974872.svg" alt="" />
      <a [routerLink]="['/']" >Project & Field Workers</a>
    </li>
    
  </ul>  
</nav>
