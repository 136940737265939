import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private Authentication: AuthenticationService) { }

  
  currentUser
  public hide: Boolean = false
  public dateTIme: Date
  ngOnInit(): void {

    this.Authentication.currentUser.subscribe(data => {
       this.currentUser = JSON.parse(localStorage.getItem('sjriUser'));
     //  console.log(this.currentUser)
    });

 //   const currentUser = JSON.parse(localStorage.getItem('sjriUser'));
    if (this.currentUser) {
      this.hide = true
    }
    let dt = localStorage.getItem("loginDt")
    this.dateTIme = new Date(dt)
    setInterval(() => {
      this.dateTIme = new Date(dt)
    }, 60000)
  }

  async openCloseSideNav() {
    var element = document.getElementById("sidebar");
    element.classList.toggle("active");
  }

  logout() {
    this.Authentication.logout()
  }

}
