<nav
  id="headerNave"
  *ngIf="hide"
  class="navbar navbar-expand-lg navbar-light bg-custom"
>
  <div class="container-fluid">
    <button
      type="button"
      id="sidebarCollapse"
      (click)="openCloseSideNav()"
      class="btn btn-info"
    >
      <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
    <button
      class="btn btn-dark d-inline-block d-lg-none ml-auto"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="fa fa-user-circle" aria-hidden="true"></i>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link last-seen" href="#">
            Login Since : {{ dateTIme | dateAgo }}
            <!-- Last login : 23 March 2020, 10:30 AM -->
          </a>
        </li>
        <!-- <li class="nav-item">
                    <a class="nav-link c-color name" href="#">
                        <img class="notification" src="assets/img/icons/notification.svg" alt="">
                    </a>
                </li> 
         <li class="nav-item">
          <a class="nav-link c-color name" href="#">John</a>
        </li> -->
        <!-- <li class="nav-item dropdown show">
                    <a class="nav-link" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="round" src="https://via.placeholder.com/25" alt="" /></a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" href="#">Profile</a>
                        <a class="dropdown-item" href="#">Logout</a> 
                      </div>
                </li> -->
        <li class="nav-item">
          <a
            class="nav-link c-color name dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
          <i class="fa fa-user" aria-hidden="true"></i>  &nbsp; {{currentUser.first_name | titlecase}} {{currentUser.last_name}} &nbsp;
          <!--<i class="fa fa-ellipsis-v" aria-hidden="true"></i>-->
          <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a >
          <!-- <a class="dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-user" aria-hidden="true"></i>&nbsp; Profile
              </a>  -->
          <div class="dropdown-menu dropdown-profile dropdown-menu-right">
            <div class="profile-drop">
              <div class="mt-3 text-center">
                <i class="fa fa-user-circle-o fa-3x" aria-hidden="true"></i>
              </div>
              <div class="mt-2 text-center">
                <strong>{{currentUser.first_name | titlecase}} {{currentUser.last_name}}</strong>
              </div>

              <div class="mt-2 text-left">
                <div class="dashed m-3">
                  <div class="mail-mobile">
                    <p style="word-break: break-all;">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i
                      >&nbsp;&nbsp;&nbsp;{{currentUser.email}}
                    </p>
                  </div>
                  <div class="mail-mobile">
                    <p class="m-0">
                      <img
                        src="assets/img/icons/phone-call.svg"
                        class="mb-2"
                        alt=""
                        srcset=""
                      />&nbsp;&nbsp;&nbsp; +91 {{currentUser.mobile_number}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <!-- <a class="dropdown-item"  href="#">My profile</a>
                  <a class="dropdown-item"  href="#">My Address</a>
                  <a class="dropdown-item" href="#">My Order</a> -->
              <a
                (click)="logout()"
                class="dropdown-item text-center logout"
                href="javascript:void(0);"
                >Logout</a
              >
            </div>
          </div>
        </li>

        <!-- profile  dropdown-->
      </ul>
    </div>
  </div>
</nav>
