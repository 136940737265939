import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './partials/nav/nav.component';
import { HeaderComponent } from './partials/header/header.component';
import { BreadcrumbComponent } from './partials/breadcrumb/breadcrumb.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './services/interceptor/jwt.interceptor';
import { ErrorInterceptor } from './services/interceptor/error.interceptor';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { CustomTitleCasePipePipe } from './pipes/custom-title-case-pipe.pipe';
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HeaderComponent,
    BreadcrumbComponent,
    DateAgoPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule   
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
