import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Research-Institute';

  constructor(public router: Router) {

  }

  isLoginPage(url) {
    let fullUrl = url.split('?')[0];
    if (fullUrl == '/login' || fullUrl == '/activation' || fullUrl == '/login/activation') {
      return true
    } else {
      return false
    }
  }
}
