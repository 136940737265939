import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  {
    path: 'user-management',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'ingredients',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/raw-ingredients/raw-ingredients.module').then(m => m.RawIngredientsModule)
  },
  {
    path: "login",
    loadChildren: () => import("./modules/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "activation",
    loadChildren: () => import("./modules/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "recipe-master",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/recipe-master/recipe-master.module").then(m => m.RecipeMasterModule)
  },
  {
    path: "project-listing",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/project-listing/project-listing.module").then(m => m.ProjectListingModule)
  },
  {
    path: "responded-data",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/responded-data/responded-data.module").then(m => m.RespondedDataModule)
  }
  ,
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/project-field-worker/project-field-worker.module").then(m => m.ProjectFieldWorkerModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
