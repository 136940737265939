import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(private Authentication: AuthenticationService) { }


  public hide: Boolean = false
  
  userType
  ngOnInit(): void {
    
    this.Authentication.currentUser.subscribe(data => {
      this.userType = JSON.parse(localStorage.getItem('sjriUser'));
   });
    
    const currentUser = JSON.parse(localStorage.getItem('sjriUser'));
    if (currentUser) {
      this.hide = true
    }
  }
}
