 

<div class="container-scroller" *ngIf="isLoginPage(router.url) == true"> <!--*ngIf="router.url == '/login'"-->
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<div class="wrapper" *ngIf="isLoginPage(router.url) == false"><!--*ngIf="router.url != '/login'"-->
  <!-- Sidebar  -->
  <app-nav></app-nav>

  <!-- Page Content  -->
  <div id="content">
    <app-header></app-header>

    <app-breadcrumb></app-breadcrumb>
    <div id="content-container">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </div>
</div>

<ng-template #template>
  <router-outlet> </router-outlet>
</ng-template>
