import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( 
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     const currentUser = JSON.parse(localStorage.getItem('sjriUser'));
    if (currentUser) {
      // authorised so return true
      return true;
    }


    // not logged in so redirect to login page with the return url
    //  this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url }});
    this.router.navigate(['/login'], 
    // { queryParams: { relogin:true }}
    );
    return false;
  }

}
