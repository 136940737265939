import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import jwt_decode from 'jwt-decode';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.access_token) {
            request = request.clone({
                setHeaders: {
                    authorization: `Bearer ${currentUser.access_token}`,
                }
            });
        }

        return next.handle(request);
    }

    getDecodedAccessToken(token: string): any {
        try {
            let decodedData = jwt_decode(token, { header: true });
            console.log(decodedData)
        }
        catch (Error) {
            console.log(Error)
            return null;
        }
    }
}